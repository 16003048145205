window.addEventListener('load', () => {
  
  // Get the modal
  var modal = document.getElementById("modal-landing-select");

  // Get the button that opens the modal
  var btn = document.getElementById("zaloguj");

  // Get the button that closes the modal
  var closeBtn = document.getElementById("close");
  
  // When the user clicks on the button, open the modal
  btn.addEventListener("click", (event) => {
    modal.style.display = "flex";
  });

  // When the user clicks anywhere outside of the modal, close it
  window.addEventListener("click", (event) => {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  });

  // When the user clicks on the close button, close it
  window.addEventListener("click", (event) => {
    if (event.target == closeBtn) {
      modal.style.display = "none";
    }
  });
});